import {
  getWorkspacesRequest,
  getWorkspacesResponse,
  type GetWorkspacesResponse,
} from '@core-api/workspaces/workspaces/{workspace_id}';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import userCache from '@mentimeter/user';
import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';

function cacheKey(workspaceId: number) {
  return `workspaces-${workspaceId}`;
}

export function useMutateWorkspaceById() {
  const swrConfig = useSWRConfig();
  return useCallback(
    (workspaceId: number) => swrConfig.mutate(cacheKey(workspaceId)),
    [swrConfig],
  );
}

export function useWorkspaceById(workspaceId?: number | null) {
  return useSWR<GetWorkspacesResponse | undefined>(
    workspaceId ? cacheKey(workspaceId) : null,
    async () => {
      if (!workspaceId) return;
      const response = await fetch(
        getWorkspacesRequest({
          region: userCache.region,
          userAuth: userCache.getToken(),
          workspaceId,
        }),
      );

      // If the response is 404, it means that the user does not belong
      // to a workspace. In that case we do not want to throw
      if (response.status === 404) {
        return;
      }

      // If the response is 403 och 401 we should not report to sentry either
      // This usually happens if the user is removed from a workspace but client cache is not revalidated
      if (response.status === 403 || response.status === 401) {
        return;
      }

      if (!response.ok) {
        try {
          const errorResponse = await response.json();
          throw new Error(errorResponse?.code);
        } catch {
          const error = new MentiError('Error on fetching workspace by id', {
            feature: 'workspaces',
          });
          captureException(error);
          throw error;
        }
      }

      const data = await getWorkspacesResponse(response);
      return data;
    },
    { revalidateOnFocus: false, revalidateIfStale: false },
  );
}
