// This file is automatically generated. DO NOT EDIT.

import { type ActiveSubscription } from '../../types/response/ActiveSubscription';
import { type MemberStats } from '../../types/response/MemberStats';
import { fromSnakeKeys } from '@api/internal';

export interface GetWorkspacesResponse {
  activeSubscription: ActiveSubscription;
  themesControl: 'everyone' | 'admin';
  aiFeaturesEnabled: boolean;
  availableFreeSeats: number;
  availableLicenses: number;
  collaborationRights: string;
  defaultThemeId: number | null;
  disableShareResults: boolean;
  discoverable: boolean;
  domainRestriction: string | null;
  emailDomains: string;
  exportRestrictionEnabled: boolean;
  groupsControlledByRole: 'member' | 'admin';
  hideUsersFromMembers: boolean | null;
  id: number;
  identifiedResponsesMode: 'enabled' | 'disabled';
  joinLinkRestrictionEnabled: boolean;
  joinPageActive: boolean;
  joinUrl: string;
  memberStats: MemberStats;
  name: string | null;
  occupiedLicenses: number;
  ownerName: string;
  requestRoleUpgradeEnabled: boolean;
  requestToJoinEnabled: boolean;
  samlLoginUrl: string | null;
  sharedSeriesAccess: 'anyone' | 'no_one';
  signupMode: string;
  url: string;
}

export async function getWorkspacesResponse(
  response: Response,
): Promise<GetWorkspacesResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetWorkspacesResponse;
}
